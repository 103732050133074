$(function(){
    $(document).on("click", "a.disabled", function(event){
        event.preventDefault();
    });
    
    $(".flash-wrap").on("click", "a.send_email", function(){
    	$(this).html("Sending...");
    });
    
	var overlays = [];
	$(document).on("click", ".creative-overlay", function(sender){
		sender.preventDefault();
		$.each(overlays, function(i,e){
			if(e.hasClass('ui-dialog-content')){
				e.dialog('close');
			}		
		});
		target = $(this).data("target");
		target_element = $("#"+target);
		title = $(this).attr("data-title");
		id = $(this).attr("data-id");
		agency_id = $(this).data('agency-id');
		campaign_id = $(this).data('campaign-id');
		campaign_creative_id = $(this).data('campaign-creative-id');
		$(".creative-overlay-title").html("Add Creative " + $(this).attr("data-creative-alpha"));
		$(".creative-overlay-number").html($(this).attr("data-creative-number"));

		url = "/refresh_creative_overlay";
		if (id != undefined)
			url = url + "?id="+id;
		if (agency_id!= undefined)
			url = url + "?agency_id="+agency_id;
		if (campaign_creative_id != undefined)
			url = url + "&campaign_creative_id=" + campaign_creative_id;
		url = url + "&campaign_id=" + campaign_id + "&i=" + $(this).attr("data-creative-number");
						
		target_element.load(url).dialog({
			show : "slide",
			hide: "toggle",
			width: "90%",
			height: "auto",
			modal: "true",
			title: title,
			position: { my: "center", at: "top+100" },
			open: function(event, ui){
				$('.ui-widget-overlay').addClass('ui-widget-overlay-override');
			},
			beforeClose: function (event, ui) {
    			$('#save-creative').trigger("reset");
    			$('.upload_creatives_div').empty();
    			$("form#save-creative").remove();
            },
		});

		overlay = target_element;
		overlays.push(target_element);
	});
	
	$(document).on("click", "#add-creative", function(sender){
		sender.preventDefault();
		var button = $(this);
		var i = parseInt($(this).attr('data-i'));
		$.ajax({
			url: "load_new_creative_dropdown",
			method: "GET",
			dataType: "json",
			data: {i: i},
			success: function(data) {
				$(data.partial).insertBefore(button);
				if (i < 9)
					$("#add-creative").attr("data-i", i+1);
				else
					$("#add-creative").hide();
			}
		});
	});

	$(document).on("click", ".pop-overlay", function(sender){
		sender.preventDefault();
		$.each(overlays, function(i,e){
			if(e.hasClass('ui-dialog-content')){
				e.dialog('close');
			}		
		});
		target = $(this).data("target");
		target_element = $("#"+target);
		title = $(this).data("title");
		
		target_element.dialog({
			show : "slide",
			hide: "toggle",
			width: "90%",
			height: "auto",
			modal: "true",
			title: title,
			open: function(event, ui){
				$('.ui-widget-overlay').addClass('ui-widget-overlay-override');
			}
		});
		overlay = target_element;
		overlays.push(target_element);
	});
	
/*	$(document).on('submit',"#submit-creative",function(e){
		creative_hash_id = $('input[name=creative_hash_id]').val();
		e.preventDefault();		
		$('#submit-creative').unbind("submit");
		var form = $(this);
		var data = form.serialize() + '&submit=' + $(this).find("input[type=submit]:focus").val();
		$.ajax({
			url: '/creatives/'+creative_hash_id+'/self_submit',
			method: "PUT",
			dataType: "json",
			data: data,
			success: function(data) {
				update_campaign_creative_dropdown(data);
				$(".creatives_overlay").dialog("close");			
			},
		   	error: function(data) {
		   		var json = $.parseJSON(data.responseText);
		   		$(".submit-creative-message").html(json.error);
		   		form.find(':submit').prop( "disabled", false );
		   	}			   		
		});		
		return false;
	});
*/
	
	$(document).on('click',"input.submit-creative",function(e){
		e.preventDefault();
	    $("#commit").val($(this).val());	
	    $("form#submit-creative").submit();
	});
	
	$(document).on('submit',"#save-creative",function(e){
		e.preventDefault();
		var form = $(this);
		save_creative(form, "Next");
	});

	$(document).on('click',".upload_creatives_div button.save-creative",function(e){
		e.preventDefault();
		form = $("form#save-creative");
		save_creative(form, $(this).text());
	});

	$(document).on('click',".upload_creatives_div button.close",function(e){
		$(".creatives_overlay").dialog("close");
	});
			
	function save_creative(form, commit) {
		$.ajax({
			url: form.attr('action'),
			method: "POST",
			dataType: "json",
			data: form.serialize() + '&commit=' + commit,
			success: function(data) {
				if (data.new_creative == true) {
					$.each($('select[id^="campaign_creatives_"]'), function() {
						i = $(".creative-overlay-number").html();
						$(this).append("<option data-name="+creative_name+" data-i="+i+" value="+data.creative_id+">"+data.creative_name_with_status_info+"</option>");
					});
					$("select#campaign_creatives_"+$(".creative-overlay-number").html()+"_creative").val(data.creative_id);			
					$("select#campaign_creatives_"+$(".creative-overlay-number").html()+"_creative").trigger('change');
					
					if ($("input#campaign_creatives_" + data.i + "_id").val() == "")
						$("input#campaign_creatives_" + data.i + "_id").val(data.campaign_creative_id);
				}
				if (data.creative_name != null) {		
					update_campaign_creative_dropdown(data);
					btn = $("button[data-id="+data.creative_id+"][data-action='edit']");
					btn.html('Edit ' + data.creative_name);		
				}
				if (data.return_val == "submitted") {
					update_campaign_creative_dropdown(data);
					$(".creatives_overlay").dialog("close");	
				} else {
					if (data.return_val != null)
						message = data.return_val;
					else
						message = "Saved";
					$(".submit-creative-message").html(message);
				}
				
				$(".creatives_overlay").html(data.uploadPartial);				
			}			   		
		});		
		return false;
	}


	
	function update_campaign_creative_dropdown(data) {
		$('select[id^="campaign_creatives_"] option[value=' + data.creative_id  + ']').text(data.creative_name_with_status_info).attr("data-name", data.creative_name);	
	}	
	
	$(document).on('change', "[name^='campaign_creatives']", function(e) {
		selected = $(this).find("option:selected");
		i = selected.data("i");
		name = selected.data("name");
		btn = $("button[data-creative-number="+i+"][data-action='edit']");
		btn.html('Edit ' + name);
		btn.attr("data-id", selected.val());
		btn.show();
	});
	
	$("#email.autocomplete").autocomplete({
    	source: function(request, response){
    		return $.ajax({
    			url: '/admin/find_user_by_email',
				dataType: "jsonp",
				data: { email: request.term },
				minLength: 3,
				success: function(data) {
					return response(data.map(function(item) {
						return {"value" : item.email};
		         	}));
		   		}
		   		
    		});
		}
	});
	
	$.fn.bootstrapBtn = $.fn.button.noConflict();

	$(".submit-form").on( "change",function() {
        $(this).closest("form").submit();
    });

	$(document).on("click", ".af-autocomplete li.ui-menu-item", function(sender){
	  	checkbox = $(this).find("input");
	  	if (checkbox.attr("disabled") != "disabled")
	  		checkbox.prop("checked", !checkbox.prop("checked"));
	});
	  
	$(document).on("click", ".af-autocomplete .ui-menu-item input:checkbox", function(sender){
	  	$(this).prop("checked",!$(this).prop("checked"));
	});

	$(document).on('focus', '.datepicker', function () {
		$('.datepicker').datepicker({
	    	dateFormat: "M d, yy",
	        onSelect: function (date, datepicker) {
	        	if (datepicker.id == "start_date") {
	        		start_date = new Date(date);
	        		end_date = $("#end_date").datepicker("getDate");
	        		if (end_date < start_date)
	        			$('#end_date').datepicker("setDate", null);            			
	        		$('#end_date').datepicker("option", "minDate", start_date);
	        	}              
	        }	    	
    	});	 
	});

		
	$(document).on("colorpickerChange", "#overlay-color-picker", function(event) {
		$('#color-picker-demo').css('background-color', event.color.toString());
	    $("input[type=hidden]#overlay_color").val(event.color.toString());
	    update_creative_preview(true);
	});   
  		
    $(document).on('change',"#vote_text, select#creative_overlay_position_y, select#creative_emoto_size_percentage, select#creative_display_overlay, #iflash_count, #flash_duration_in_second",function(e){
    	$("input[type=hidden]#" + $(this).attr("id")).val($(this).val());	    		
    	update_creative_preview(true, $(this));
    });
    
    $(document).on("click", "#replay_preview", function(e) {
    	update_creative_preview(true);
    });
    	
	$(document).on("click", ".creative_variant_font_size i", function(sender) {
		var variant_id = $(this).parent().data("variant-id");
		var rem = $("#font_size_rem_" + variant_id).val();

		if (rem == "") {
			var emoto_size = $("select#creative_variant_emoto_size_percentage[data-variant-id='" + variant_id + "']").val();
			
			if (emoto_size == "")
				emoto_size = $("select#creative_emoto_size_percentage").val();
	
			$.ajax({
				url: "/creatives/recalc_variant/" + variant_id,
				async: false,
				method: "GET",
				dataType: "json",
				data: {emoto_size: emoto_size},
				success: function(response) {
					rem = response.font_size_rem_calculated;					
				}   		
			});
		}
		
		rem = parseFloat(rem);	    				
		
		change = $(this).data("change");
		step = 0.2;
		
		if (change == "plus")
			new_rem = rem + step;
		else if (change == "minus")
			new_rem = rem - step;
			
		$("#font_size_rem_" + variant_id).val(new_rem);
		
		refresh_variant_preview(variant_id);	
	});
	
    $(document).on('change',"select#creative_variant_overlay_position_y, select#creative_variant_emoto_size_percentage, select#creative_variant_display_overlay",function(e){
    	variant_id = $(this).data("variant-id"); 
    	
    	if ($(this).attr('id') == "creative_variant_emoto_size_percentage") {
    		var emoto_size = $(this).val();
    		
 			$.ajax({
				url: "/creatives/recalc_variant/" + variant_id,
				async: false,
				method: "GET",
				dataType: "json",
				data: {variant: {emoto_size: emoto_size}},
				success: function(response) {
					$("#font_size_rem_" + variant_id).val(response.font_size_rem_recalc);					
				}   		
    		});
    	}
    	
		refresh_variant_preview(variant_id);
    });	
    
    $(document).on('click', '#customize_variant', function(e) {
    	e.preventDefault();
    	variant_id = $(this).data("variant-id");     	
    	$(".variant_form[data-variant-id='" + variant_id + "']").toggle();
    	$(this).html($(this).html() === "Customize Options" ? "Close Options" : "Customize Options");
    });
    
    $(document).on('click', '.reset_variant', function(e) {
    	e.preventDefault();
    	variant_id = $(this).data("variant-id");  

		$.ajax({
			url: '/creatives/reset_variant_options/' + variant_id,
			async: false,
			method: "GET",
			dataType: "json",
			success: function(data) {
				$(".variant-options[data-variant-id='" + variant_id + "']").html(data.variant_options_partial);
			}
		});
		
		refresh_variant_preview(variant_id);	
    });
    
    function refresh_variant_preview(variant_id) {
    	params = get_single_embed_params(variant_id, false);
 		preview = $("iframe.preview#" + variant_id);
		preview.attr("src", "/creatives/" + variant_id + "/single_embeded?" + params);        	
    }
});

window.update_creative_preview = function(update_all, target) {
	var variant_hash_id = $('input#variant_id').val();
	
	var overlay_position = $("select#creative_overlay_position_y").val();
	
	$.ajax({
		url: '/creatives/get_preview_height/'+variant_hash_id,
		async: false,
		method: "GET",
		dataType: "json",
		success: function(data) {
			var div_width = $("#preview").width();
			if (div_width > 300)
				div_width = 300;
			var height = div_width/data.ratio;
			if (overlay_position > 100)
				height = height + data.overlay_height;

	    	var params = get_single_embed_params();				   
			$.ajax({
				url: '/creatives/update_preview/'+variant_hash_id+'?' + params + "&height="+height,
				method: "GET",
				dataType: "html",
				success: function(data) {
					$(".general-form-body .preview").html(data);
					$("#preview_iframe").width(div_width);
					$("#preview_iframe").height(height);							

					if (update_all == true) {
						$.each($("iframe.preview"), function() {							
							variant_id = $(this).attr("id");
							params = get_single_embed_params(variant_id, true, target);
							$(this).attr("src", '/creatives/'+variant_id+'/single_embeded'+'?' + params);
						});
					}
				}	   		
			});	
		}
	});		
	return false;
};	
    
window.get_single_embed_params = function(variant_id, override, target) {

	var overlay_position_select = $("select#creative_overlay_position_y");	
	var emoto_size_select = $("select#creative_emoto_size_percentage");				    		
	var display_overlay_select = $("select#creative_display_overlay");
			
	if (variant_id != undefined) {

    	var variant_overlay_position_select = $("select#creative_variant_overlay_position_y[data-variant-id='" + variant_id + "']");	  		
    	var variant_emoto_size_select = $("select#creative_variant_emoto_size_percentage[data-variant-id='" + variant_id + "']");	    	
    	var variant_display_overlay_select = $("select#creative_variant_display_overlay[data-variant-id='" + variant_id + "']");									
		var variant_rem_field = $("#font_size_rem_" + variant_id);
	
		if (override == true) {
			// Creative options have changed, override any unsaved variant options
									
			$.ajax({
				url: "/creatives/recalc_variant/" + variant_id,
				async: false,
				method: "GET",
				dataType: "json",
				data: {emoto_size: emoto_size_select.val()},
				success: function(variant) {					
					if (variant.overlay_position_y != null)
						variant_overlay_position_select.val(variant.overlay_position_y);
					else
						variant_overlay_position_select.val("");

					if (variant.emoto_size_percentage != null) {
						variant_emoto_size_select.val(variant.emoto_size_percentage);						
					} else {
						variant_emoto_size_select.val("");
						
						if (target != undefined && target.attr('id') == "creative_emoto_size_percentage")
							variant_rem_field.val(variant.font_size_rem_recalc);								
					}
					
					if (variant.display_overlay != null) 
						variant_display_overlay_select.val(variant.display_overlay.toString());
					else
						variant_display_overlay_select.val("");
				}
			});			
		}

    	if (variant_overlay_position_select.val() != "")
    		overlay_position_select = variant_overlay_position_select;
			
    	if (variant_emoto_size_select.val() != "") 
    		emoto_size_select = variant_emoto_size_select;		
    		
    	if (variant_display_overlay_select.val() != "")
    		display_overlay_select = variant_display_overlay_select;    		

		if (variant_rem_field.val() != "")
			var rem = variant_rem_field.val();	
	}

	var overlay_position = overlay_position_select.val();

	if (overlay_position > 100) {
		emoto_size_select.val(100);
		display_overlay_select.val("true");
	}
	
	var emoto_size = emoto_size_select.val();
	var display_overlay = display_overlay_select.val();	   	
					
	var vote_text = $("#vote_text").val();
	if(vote_text) 
		vote_text = encodeURIComponent(vote_text);
	else
		vote_text = "";		
		
	var iflash_count = $("#iflash_count").val();	
	var flash_duration_in_second = $("#flash_duration_in_second").val();		

	var overlay_color = $("#color-picker-demo").css('background-color');
	
	params = "overlay_position_select="+overlay_position+"&emoto_size_percentage="+emoto_size+"&display_overlay="+display_overlay+"&overlay_color="+overlay_color + "&vote_text=" + vote_text + "&iflash_count=" + iflash_count + "&flash_duration_in_second=" + flash_duration_in_second;	
	
	if (rem != undefined)
		params += "&font_size_rem=" + rem;

	return params;
};