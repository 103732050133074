// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// import the bootstrap javascript module
//import "bootstrap"



// import the application.scss we created for the bootstrap CSS (if you are not using assets stylesheet)
import "../stylesheets/application.scss"

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


import "../js/bootstrap.bundle.min"
window.bootstrap = require('../js/bootstrap.bundle.min.js');
import "../js/jquery-ui.min"
import "../js/site"
import "../js/bootstrap-colorpicker"

